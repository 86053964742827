import "./App.scss";

import Sim from "./type2/Sim";

const App = () => {
	return (
		<div className="App">
			<Sim></Sim>
		</div>
	);
};

export default App;
